import { useSpringCarousel } from 'react-spring-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

type TestimonialProps = {
   testimonial: any;
};

const Testimonial = ({ testimonial }: TestimonialProps) => {
   return (
      <div className='item'>
         <div className='box'>
            <div className='detail-box'>
               <h5>{testimonial.name}</h5>
               <FontAwesomeIcon icon={faQuoteLeft} />
               <p>{testimonial.message}</p>
            </div>
         </div>
      </div>
   );
};

const Testimonials = () => {
   const testimonials = [
      { id: 1, name: 'Anne-caroline de Moulins', message: "Professionnelle, douce et à l'écoute de vos animaux. Je recommande sans hésité ses services." },
      {
         id: 2,
         name: 'Amandine Naep',
         message:
            "Je recommande vivement Lise, pour ses nombreux conseils, c'est  quelqu'un de très à l'aise avec les animaux (petits et grand), mon cheval est resté très détendu pendant toute la séance 🤗🐴",
      },
      {
         id: 3,
         name: 'Simon Blot',
         message: "Je vous conseille ses services, sans hésitation ! 🐈🐕 Professionnelle, à l'écoute, enjouée, sympathique, vos animaux retrouveront leur vitalité ! 🐄🐏🐎🐔",
      },
      {
         id: 4,
         name: 'Frédérique Albert',
         message:
            "Je recommande Lise DAGUET. Pendant plusieurs mois Noréo, Berger Belge Groenendael, se levait fréquemment sur 3 pattes. Depuis sa séance d'ostéopathie avec Lise, plus aucun souci, elle a su établir le contact avec lui pour que la séance se passe bien.",
      },
   ];
   const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
      itemsPerSlide: 2,
      withLoop: true,
      items: testimonials.map(item => ({
         id: item.id,
         renderItem: <Testimonial testimonial={item} />,
      })) as any[],
   });

   return (
      <section id='testimonials' className='client_section layout_padding'>
         <div className='container'>
            <div className='heading_container'>
               <h2>Témoignages</h2>
            </div>
            <div>
               <div>{carouselFragment}</div>
               <div className='text-center'>
                  <button onClick={slideToPrevItem}>
                     <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <button onClick={slideToNextItem}>
                     <FontAwesomeIcon icon={faArrowRight} />
                  </button>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Testimonials;
