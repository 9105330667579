import care from '../images/care.jpg';
import React from 'react';
import { PageProps } from '../type/PageProps';

const Care = ({ showDetails = false }: PageProps) => {
   return (
      <section className={showDetails ? 'care_section layout_padding' : 'care_section'}>
         <div className='container'>
            <div className={showDetails ? 'row pb-3' : 'row'}>
               <div className='col-md-6'>
                  <div className='detail-box'>
                     <div className='heading_container'>
                        <h2>L'Ostéopathie animale</h2>
                     </div>
                     {showDetails && (
                        <>
                           <p>L’ostéopathie animale est appliquée en France depuis les années 1980.</p>
                           <p>
                              Cette médecine alternative était pratiquée essentiellement sur les chevaux. Désormais, elle concerne tous types d’animaux : chiens, chats, bovins et
                              NAC notamment.
                           </p>
                        </>
                     )}
                     <p>
                        Pratique holistique, elle permet d’intervenir de manière globale sur le corps de l’animal afin de soulager ses douleurs musculaires, favoriser sa
                        croissance, améliorer ses performances et ses capacités de récupération après un effort, mais également en accompagnement de certaines pathologies, en
                        coopération avec votre vétérinaire, comme par exemple la hernie discale ou l’insuffisance rénale …
                     </p>
                     <p>L’ostéopathie animale s’avère ainsi efficace pour redonner équilibre et mieux-être à l’organisme de votre animal.</p>
                     {showDetails && (
                        <>
                           <p>
                              Ostéopathe animalier, je suis amenée à soulager les douleurs, raideurs, boiteries, accompagner l’arthrose, la croissance, le vieillissement et les
                              troubles du comportement.&nbsp;
                           </p>
                           <p>
                              J’interviens en curatif mais également en préventif. Une consultation annuelle de «&nbsp;check up&nbsp;» est recommandée tout au long de la vie de
                              votre animal.
                           </p>
                           <p>
                              Pour ce faire, je recours à des <strong>techniques manuelles douces et diversifiées</strong>, adaptées au besoin et à votre animal, visant à apporter
                              un soulagement : <strong>osteo-articulaires, crâniennes et crânio-sacrées, viscérales, myofasciales, tissulaires, ftm, émotionnelles …&nbsp;</strong>
                           </p>
                        </>
                     )}
                     {!showDetails && (
                        <>
                           <a className='mr-3' href='/seance'>
                              Déroulement d'une séance
                           </a>
                           <a className='outline' href='/osteopathie-animale'>
                              En savoir plus
                           </a>
                        </>
                     )}
                  </div>
               </div>
               <div className='col-md-6'>
                  <div className='img-box'>
                     <img src={care} alt='' />
                  </div>
               </div>
            </div>
            {showDetails && (
               <div className='row mt-5 align-items-baseline'>
                  <div className='col-md-6'>
                     <div className='detail-box'>
                        <div className='heading_container'>
                           <h2>Concept thérapeutique et philosophique</h2>
                        </div>
                        <p>
                           L'ostéopathie redonne de la mobilité à l'ensemble du corps et optimise l'état de santé en agissant sur différents systèmes. L'ostéopathe travaille sur
                           les structures ostéo-articulaires, musculaires, viscérales et/ou crâniennes dont les tissus manquent de mobilité et ne permettent plus au corps de
                           s'adapter à ses contraintes internes et environnementales.
                        </p>
                        <p>
                           Ces blocages peuvent entraver la libre circulation des fluides, l'équilibre du corps, et provoquer des lésions et des douleurs. En libérant les
                           restrictions de mobilités, l'ostéopathie permet de restaurer l'autorégulation du corps.
                        </p>
                        <p>
                           L'ostéopathie s'adresse aux troubles fonctionnels, complète la médecine mais ne la substitue pas. La médecine allopathique est axée sur la maladie en
                           privilégiant la chimie du corps et sa réponse aux préparatifs pharmaceutiques. La profession ostéopathique s'oriente essentiellement sur le patient.
                        </p>
                        <p>
                           L'ostéopathie pousse à l'extrême ce principe d'autoguérison du corps en s'appuyant sur une connaissance approfondie de l'anatomie et de la physiologie.
                           Elle s'organise autour de trois principes fondamentaux : l'unité de corps, la relation structures/fonctions et le principe d'autoguérison.
                        </p>
                     </div>
                  </div>
                  <div className='col-md-6'>
                     <div className='detail-box'>
                        <div className='heading_container'>
                           <h2>Les principes fondamentaux</h2>
                        </div>
                        <p>
                           <strong>Unité de corps</strong> : L'ostéopathie envisage l'individu dans sa globalité. Toutes les parties qui forment l'ensemble du corps sont reliées
                           entre elles. Si une structure présente une altération, son dysfonctionnement se retentira sur le fonctionnement de l'ensemble du corps.
                        </p>
                        <p>
                           <strong>Relations structures/fonctions</strong> : Chaque structure du corps a une fonction physiologique. Chaque fonction entretient une certaine
                           structure et la structure gouverne la fonction.
                        </p>
                        <p>
                           <strong>Autoguérison</strong> : Le corps dispose de nombreux systèmes de réparation, d'adaptation, de défense ou de compensation. Une dysfonction peut se
                           résorber naturellement.
                        </p>
                     </div>
                  </div>
               </div>
            )}
         </div>
      </section>
   );
};

export default Care;
