import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/bootstrap.css';
import './styles/style.css';
import './styles/responsive.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
   <React.StrictMode>
      <BrowserRouter>
         <Routes>
            <Route path='*' element={<App />} />
         </Routes>
      </BrowserRouter>
   </React.StrictMode>
);
