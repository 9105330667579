import about from '../images/me.jpg';
import about2 from '../images/me3.jpg';
import React from 'react';
import { PageProps } from '../type/PageProps';

const About = ({ showDetails = false }: PageProps) => {
   return (
      <section className='about_section layout_padding'>
         <div className='container'>
            <div className='row pb-3'>
               <div className='col-md-6'>
                  <div className='img-box'>
                     <img src={showDetails ? about2 : about} alt='' />
                  </div>
               </div>
               <div className='col-md-6'>
                  <div className='detail-box'>
                     <div className='heading_container'>
                        <h2>Présentation</h2>
                     </div>
                     {!showDetails && (
                        <>
                           <p>
                              Titulaire d’un diplôme d’ostéopathie animale du National Institute of Animal Osteopathy (NIAO), inscrite dans le registre national d’aptitudes (RNA)
                              auprès de l’Ordre National des Vétérinaires sous le numéro OA394 et enregistrée à l’ordre régional, je mets à votre disposition mes solides
                              compétences acquises lors de mon parcours professionnel et personnel pour apaiser les maux que peuvent rencontrer vos animaux.
                           </p>
                           <a className='outline' href='/presentation'>
                              En savoir plus
                           </a>
                        </>
                     )}
                     {showDetails && (
                        <>
                           <p>
                              Domiciliée à Durtal dans le département de la Sarthe, je propose des soins adaptés permettant de réduire les dysfonctionnements moteurs, et d’apporter
                              aux animaux tout le bien-être dont ils ont besoin.
                           </p>
                           <p>Passionnée d’animaux depuis ma tendre enfance, j'ai toujours su que je ferait de ma passion mon métier.</p>
                           <p>
                              Titulaire d’un diplôme d’ostéopathie animale du National Institute of Animal Osteopathy (NIAO), inscrite dans le registre national d’aptitudes (RNA)
                              auprès de l’Ordre National des Vétérinaires sous le numéro OA394 et enregistrée à l’ordre régional, je mets à votre disposition mes solides
                              compétences acquises lors de mon parcours professionnel et personnel pour apaiser les maux que peuvent rencontrer vos animaux.
                           </p>
                           <p>Dans le but de parachever mes connaissances dans le monde animal, équin et canin, j'ai effectuée, en parallèle de mes études, diverses formations.</p>
                        </>
                     )}
                  </div>
               </div>
            </div>
            {showDetails && (
               <div className='row mt-5'>
                  <div className='col-md-6'>
                     <div className='detail-box'>
                        <div className='heading_container'>
                           <h2>Cursus</h2>
                        </div>
                        <p>
                           Titulaire en juin 2010 d’un Baccalauréat Scientifique avec mention assez bien. J'ai également effectuée des études en Classe préparatoire aux concours
                           aux entrées des écoles vétérinaires BCPST (Bio, Chimie, Physique, Science de la Terre) à l'université de Rouen de 2013 à 2015.
                        </p>
                        <p>En 2015, j'intègre le NIAO (National Institute of Animal Osteopathy) Laval.</p>
                        <p>En 2018, je devient titulaire d'un diplôme d'ostéopathie animale.</p>
                     </div>
                  </div>
                  <div className='col-md-6'>
                     <div className='img-box'>
                        <img src={about} alt='' />
                     </div>
                  </div>
               </div>
            )}
         </div>
      </section>
   );
};

export default About;
