import who from '../images/who.jpg';
import horse from '../images/horse2.jpg';
import cat from '../images/cat.jpg';
import dog from '../images/dog2.jpg';
import cow from '../images/cow.jpg';
import nac from '../images/nac.jpg';
import { PageProps } from '../type/PageProps';

const Who = ({ showDetails = false }: PageProps) => {
   return (
      <section className='about_section layout_padding'>
         <div className='container'>
            <div className={showDetails ? 'row pb-3' : 'row'}>
               {!showDetails && (
                  <div className='col-md-6'>
                     <div className='img-box'>
                        <img src={who} alt='' />
                     </div>
                  </div>
               )}
               <div className={showDetails ? 'col-md-12' : 'col-md-6'}>
                  <div className='detail-box'>
                     <div className='heading_container'>
                        <h2>Pour qui ?</h2>
                     </div>
                     <p>
                        Cette médecine alternative était pratiquée essentiellement sur les chevaux. Désormais, elle concerne tous types d’animaux : chiens, chats, bovins et NAC
                        notamment.
                     </p>
                     {!showDetails && (
                        <a className='outline' href='/pour-qui'>
                           En savoir plus
                        </a>
                     )}
                  </div>
               </div>
            </div>
            {showDetails && (
               <>
                  <div className='row pb-3 mt-5'>
                     <div className='col-md-6'>
                        <div className='img-box'>
                           <img src={horse} alt='' />
                        </div>
                     </div>
                     <div className='col-md-6'>
                        <div className='detail-box'>
                           <div className='heading_container'>
                              <h2>Chevaux</h2>
                           </div>
                           <p>
                              Destinés aux poulains et chevaux de clubs, de propriétaires, de loisirs ou de courses et en tant que médecine douce, l’ostéopathie constitue, pour les
                              éleveurs d’équidés, un service indispensable.
                           </p>
                           <p>
                              Dans les années 1970, l’ostéopathie a intégré le monde des courses équestres afin d’améliorer la capacité physique et les performances des chevaux.
                           </p>
                           <p>
                              Dotée d’une connaissance approfondie de l’anatomie et physionomie animale, je suis apte à aider votre cheval à retrouver sa forme et sa vitalité,
                              ainsi qu'à le soulager de divers troubles et pathologies comme :
                           </p>
                           <ul>
                              <li>Problèmes locomoteurs : boiterie, raideur, irrégularité, asymétrie musculaire suite à un traumatisme</li>
                              <li>Troubles du dos : notamment une gêne au sanglage, une douleur lors du pansage du dos…</li>
                              <li>Troubles du comportement : de la prise alimentaire, baisse de performance et récupération difficile</li>
                              <li>
                                 Troubles pendant le travail: défaut d’engagement, mauvaise incurvation, difficulté à baisser les hanches, changement de pied difficile, dur en
                                 bouche, tourne mal, ruade, refus… Lors du trot, il se décale dans le sulky, dévie de la trajectoire, part à la faute
                              </li>
                              <li>Troubles génitaux : irrégularités des chaleurs, douleurs ovariennes, problèmes de fertilités</li>
                              <li>Troubles viscéraux : de l’appareil urinaire: cystite récurrente, de l’appareil digestif répétitif, diarrhée, constipation</li>
                              <li>Problèmes respiratoires et problèmes de peau</li>
                              <li>Suivie de gestation chez la jument, croissance du poulain.</li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className='row pb-3 mt-5'>
                     <div className='col-md-6'>
                        <div className='detail-box'>
                           <div className='heading_container'>
                              <h2>Chiens</h2>
                           </div>
                           <p>
                              L’ostéopathie canine est conçue pour soulager les douleurs et troubles de votre chien. Elle permet également d’apaiser plusieurs maux comme les
                              tensions de l’organisme.
                           </p>
                           <p>Elle favorise en outre une bonne circulation sanguine et lymphatique, apportant plus d’énergie à votre chien.</p>
                           <p>
                              L’ostéopathie canine est une médecine manuelle, énergétique qui s’invite dans notre quotidien depuis quelque temps en raison de ses vertus
                              bienfaisantes pour votre chien.
                           </p>
                           <p>Cette pratique a en effet pour objectif de résoudre divers troubles que le chien peut rencontrer tout au long de sa vie, tels que :</p>
                           <ul>
                              <li>Les raideurs aux articulations ou une boiterie</li>
                              <li>Les troubles digestifs comme le vomissement</li>
                              <li>Les troubles de la croissance ou liés au vieillissement (arthrose)</li>
                              <li>Les affections cutanées comme la dermatite</li>
                              <li>Les troubles du comportement (anxiété, agressivité, etc)</li>
                              <li>Les troubles de la reproduction comme la mauvaise fertilité, les chaleurs silencieuses, etc.</li>
                              <li>En préventif pour lui donner un meilleur confort de vie.</li>
                           </ul>
                        </div>
                     </div>
                     <div className='col-md-6'>
                        <div className='img-box'>
                           <img src={dog} alt='' />
                        </div>
                     </div>
                  </div>
                  <div className='row pb-3 mt-5'>
                     <div className='col-md-6'>
                        <div className='img-box'>
                           <img src={cat} alt='' />
                        </div>
                     </div>
                     <div className='col-md-6'>
                        <div className='detail-box'>
                           <div className='heading_container'>
                              <h2>Chat</h2>
                           </div>
                           <p>
                              L’ostéopathie féline est une pratique thérapeutique qui consiste à manipuler le système musculaire afin d’apaiser certains maux, tels que les douleurs
                              de croissance.
                           </p>
                           <p>
                              Comme pour les chiens, l’ostéopathie pour chats agit sur plusieurs pathologie telles que les troubles neurologiques ou dermatologiques, les problèmes
                              locomoteurs, les troubles viscéraux, les troubles du comportement, ainsi que les traumatismes suite à un accident.
                           </p>
                           <p>
                              En ostéopathie animale, il existe de nombreuses techniques qui peuvent soulager votre chat en fonction des affections qu’il présente. De ce fait, si
                              votre compagnon à quatre pattes a perdu l’appétit, que sa vitalité a baissé d’une façon notoire ou qu’il a changé de comportement, n’hésitez pas à me
                              contacter.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className='row pb-3 mt-5'>
                     <div className='col-md-6'>
                        <div className='detail-box'>
                           <div className='heading_container'>
                              <h2>Bovins</h2>
                           </div>
                           <p>
                              L’ostéopathie bovine est une médecine ancestrale essentiellement manuelle, aujourd’hui couramment utilisée afin de prévenir les troubles fonctionnels
                              des bovins.
                           </p>
                           <p>Cette thérapie est efficace pour dénouer les tensions et apaiser les blocages existant entre les différentes parties de l’organisme.</p>
                           <p>
                              En m’appuyant sur une connaissance précise de tous les secteurs de la filière élevage, j’utilise l’ostéopathie bovine pour aider les éleveurs, entre
                              autre, à optimiser la reproduction de leurs cheptels.
                           </p>
                           <p>L’ostéopathie bovine peut, en effet, intervenir dans différentes situations :</p>
                           <ul>
                              <li>Troubles respiratoires</li>
                              <li>Traumatismes</li>
                              <li>Problèmes de reproduction chez les vaches</li>
                              <li>Problèmes viscéraux chroniques</li>
                              <li>Vaches couchées</li>
                              <li>Suite au vêlage</li>
                              <li>Troubles locomoteurs : boiteries, raideurs ou tremblement</li>
                           </ul>
                        </div>
                     </div>
                     <div className='col-md-6'>
                        <div className='img-box'>
                           <img src={cow} alt='' />
                        </div>
                     </div>
                  </div>
                  <div className='row pb-3 mt-5'>
                     <div className='col-md-6'>
                        <div className='img-box'>
                           <img src={nac} alt='' />
                        </div>
                     </div>
                     <div className='col-md-6'>
                        <div className='detail-box'>
                           <div className='heading_container'>
                              <h2>NAC</h2>
                           </div>
                           <p>
                              Vous possédez un lapin, un hamster, un oiseau ou autres ? J'accompagne vos nouveaux animaux de compagnie afin d’optimiser leurs capacités physiques.
                              En effet, l’ostéopathe est en mesure d’améliorer le bien-être des vos animaux en favorisant l’auto-guérison lorsque ces symptômes apparaissent :
                           </p>
                           <ul>
                              <li>La diminution de la vitalité</li>
                              <li>La perte d’appétit ou de l’état général</li>
                              <li>Trouble digestif répétitif : diarrhée, constipation, vomissement, régurgitation, dysphagie, amaigrissement chronique</li>
                              <li>Problèmes neurologiques : Paralysie partielle, faiblesse générale ou locale, hernies discale</li>
                              <li>Problèmes de peau</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </>
            )}
         </div>
      </section>
   );
};

export default Who;
