import care from '../images/care.jpg';
import dog from '../images/dog.jpg';
import horse from '../images/horse2.jpg';
import React from 'react';

const Work = () => {
   return (
      <section className='care_section layout_padding'>
         <div className='container'>
            <div className='row pb-3'>
               <div className='col-md-6'>
                  <div className='detail-box'>
                     <div className='heading_container'>
                        <h2>Le cadre et la durée d'une séance</h2>
                     </div>
                     <p>
                        Ostéopathe animalier basée à Durtal (72) dans le secteur du Mans et d'Angers, je me déplace à domicile, dans vos écuries, dans des cabinets vétérinaires
                        partenaires, pour des séances d’ostéopathie visant à maintenir votre animal dans un état de santé optimal et réduire les risques de pathologie.
                     </p>
                     <p>C'est dans un cadre de bienveillance que je reçois également vos petits animaux: chats, chiens, chevaux et autres NAC.</p>
                     <p>La consultation de votre animal dure généralement entre 45 min et 1 heure.</p>
                  </div>
               </div>
               <div className='col-md-6'>
                  <div className='img-box'>
                     <img src={care} alt='' />
                  </div>
               </div>
            </div>
            <div className='row mt-5 pb-3'>
               <div className='col-md-6'>
                  <div className='img-box'>
                     <img src={horse} alt='' />
                  </div>
               </div>
               <div className='col-md-6'>
                  <div className='detail-box'>
                     <div className='heading_container'>
                        <h2>Le déroulement d'une séance</h2>
                     </div>
                     <p>
                        La séance se déroule en cinq phases :
                        <ul>
                           <li>
                              Le <strong>recueil de l’ensemble des informations</strong> permettant de mieux connaître l’animal, son mode de vie, ses antécédents médicaux et son
                              histoire personnelle.
                           </li>
                           <li>
                              Une phase d’<strong>observation</strong> statique et en mouvement, ainsi que de l’état de santé général pour confirmer qu’il est apte à une
                              consultation d’ostéopathie.
                           </li>
                           <li>
                              Une <strong>palpation</strong> minutieuse de l’animal et un ensemble de tests de mobilité qui me permettent d’établir un diagnostic ostéopathique
                              fondé sur mon ressenti et mes observations.
                           </li>
                           <li>
                              Après un échange sur le diagnostic, le <strong>traitement</strong> en lui-même. Celui-ci consiste à agir sur les zones de dysfonction pour leur
                              redonner de la mobilité. L’objectif est de permettre une bonne communication et un fonctionnement harmonieux de l’ensemble de l’organisme ; toutes les
                              parties du corps étant reliées entre elles par différentes structures (os, muscles, fascias, vaisseaux sanguins, nerfs …).
                           </li>
                           <li>
                              La phase d’<strong>explication et de conseil</strong>. Je vous restitue mon ressenti et vous explique le déroulement de la séance. Je vous apporte des
                              conseils pour le bien être de votre animal, des indications sur la pratique sportive et l’activité des jours suivants ou des recommandations à plus
                              long terme. Je suggère également si nécessaire un suivi ostéopathique régulier et en préconise avec vous la fréquence.
                           </li>
                        </ul>
                     </p>
                  </div>
               </div>
            </div>
            <div className='row mt-5'>
               <div className='col-md-6'>
                  <div className='detail-box'>
                     <div className='heading_container'>
                        <h2>Conseils</h2>
                     </div>
                     <p>
                        Thérapeute de première intention (pouvant intervenir sans prescription médicale) reconnue auprès de l’Ordre National des Vétérinaires, je serai capable de
                        vous réorienter vers le professionnel pertinent si nécessaire (vétérinaire, nutritionniste physiothérapeute, comportementaliste, maréchal ferrant …).
                        N’hésitez pas à me contacter.
                     </p>
                     <p>
                        Il est conseillé de faire un <strong>contrôle ostéopathique 1 à 2 fois par an</strong>, selon l'activité exercée par l'animal et ses éventuelles
                        pathologies. Le suivi ostéopathique permet en effet de limiter les nouvelles chaînes lésionnelles et de prévenir l'apparition de certaines maladies. En
                        effet, <strong>l'ostéopathie a également un rôle préventif !</strong>
                        <br />
                        Un suivi ostéopathique avant, pendant et après les grosses compétitions peut également être mis en place.
                     </p>
                     <p>
                        En cas de chute, de traumatisme ou de colique, il est important de faire voir l'animal rapidement, afin d'éviter que de nombreuses compensations se mettent
                        en place avec le facteur temps (gros verrouillages articulaires, adhérence, arthrose...).
                     </p>
                  </div>
               </div>
               <div className='col-md-6'>
                  <div className='img-box'>
                     <img src={dog} alt='' />
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Work;
