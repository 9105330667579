import { useState } from 'react';

const Header = () => {
   const [navClass, setNavClass] = useState('overlay');
   const [btnClass, setBtnClass] = useState('custom_menu-btn');
   const openNav = () => {
      setNavClass(navClass.includes('menu_width') ? 'overlay' : 'overlay menu_width');
      setBtnClass(btnClass.includes('menu_btn-style') ? 'custom_menu-btn' : 'custom_menu-btn menu_btn-style');
   };

   return (
      <header className='header_section'>
         <div className='container-fluid'>
            <nav className='navbar navbar-expand-lg custom_nav-container'>
               <a className='navbar-brand' href='/'>
                  <span>Lise Daguet</span>
                  <small>Ostéopathe Animalier</small>
               </a>
               <div className='' id=''>
                  <div className={btnClass}>
                     <button onClick={openNav}>
                        <span className='s-1'> </span>
                        <span className='s-2'> </span>
                        <span className='s-3'> </span>
                     </button>
                  </div>
                  <div className={navClass}>
                     <div className='overlay-content'>
                        <a href='/'>Accueil</a>
                        <a href='/presentation'>Présentation</a>
                        <a href='/osteopathie-animale'>L'Ostéopathie animale</a>
                        <a href='/seance'>Déroulement d'une séance</a>
                        <a href='/pour-qui'>Pour qui ?</a>
                        <a href='/tarifs'>Tarifs</a>
                        <a href='/temoignages'>Témoignages</a>
                        <a href='/contact'>Contact</a>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
      </header>
   );
};

export default Header;
