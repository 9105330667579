import React from 'react';
import { Footer, Header, Home } from './components';
import bg from './images/content-bg.jpg';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Contact, MainPage, Osteopathie, Presentation, Prices, Seance, Testimonials, WhosFor } from './containers';

const App = () => {
   const location = useLocation();
   return (
      <>
         {location.pathname === '/' ? <Home /> : <Header />}
         <div className='main_content'>
            <div className='main_content_bg'>
               <img src={bg} alt='' />
            </div>
            <Routes>
               <Route path='/' element={<MainPage />} />
               <Route path='/presentation' element={<Presentation />} />
               <Route path='/osteopathie-animale' element={<Osteopathie />} />
               <Route path='/seance' element={<Seance />} />
               <Route path='/pour-qui' element={<WhosFor />} />
               <Route path='/tarifs' element={<Prices />} />
               <Route path='/temoignages' element={<Testimonials />} />
               <Route path='/contact' element={<Contact />} />
            </Routes>
            <Footer />
         </div>
      </>
   );
};

export default App;
