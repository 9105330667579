import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

const SocialBox = () => {
   return (
      <div className='social_box'>
         <a href=''>
            <i className='fa fa-facebook' aria-hidden='true'></i>
            <FontAwesomeIcon icon={faFacebook} />
         </a>
         <a href=''>
            <FontAwesomeIcon icon={faTwitter} />
         </a>
         <a href=''>
            <FontAwesomeIcon icon={faLinkedin} />
         </a>
         <a href=''>
            <FontAwesomeIcon icon={faInstagram} />
         </a>
         <a href=''>
            <FontAwesomeIcon icon={faYoutube} />
         </a>
      </div>
   );
};

export default SocialBox;
