import { Contacts, SocialBox } from './index';

const Footer = () => {
   const initialDate: number = 2024;
   const currentDate = new Date().getFullYear();
   const date: string = initialDate !== currentDate ? `${initialDate}-${currentDate}` : `${initialDate}`;
   return (
      <>
         <section className='info_section layout_padding2'>
            <div className='info_container '>
               <div className='container'>
                  <div className='row'>
                     <div className='col-md-4'>
                        <h6>Liens utiles</h6>
                        <div className='info_link-box'>
                           <ul>
                              <li className='active'>
                                 <a href='/osteopathie-animale'>L'Ostéopathie animale</a>
                              </li>
                              <li>
                                 <a href='/seance'>Déroulement d'une séance</a>
                              </li>
                              <li>
                                 <a href='/pour-qui'>Pour qui ?</a>
                              </li>
                              <li>
                                 <a href='/tarifs'>Tarifs</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className='col-md-4'>
                        <h6>Coordonnées</h6>
                        <div className='contact_items'>
                           <Contacts />
                        </div>
                     </div>
                     <div className='col-md-4'>
                        <div className='info_form '>
                           <h6>Réseaux sociaux</h6>
                           <SocialBox />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <footer className='container-fluid footer_section '>
            <p>
               &copy; {date} All Rights Reserved. Design by{' '}
               <a href='https://hugobisson.fr' target='_blank'>
                  Hugo BISSON
               </a>
            </p>
         </footer>
      </>
   );
};

export default Footer;
