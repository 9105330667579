import bg from '../images/horse.jpg';
import { Header } from './index';

const Home = () => {
   return (
      <div className='header_area '>
         <div className='header_bg_box'>
            <img src={bg} alt='' />
         </div>
         <Header />
         <section className='slider_section'>
            <div className='container'>
               <div className='row'>
                  <div className='col-md-6 '>
                     <div className='detail-box'>
                        <h1>
                           Je prends soin <br />
                           de vos animaux
                        </h1>
                        <p>Ostéopathe Animalier inscrite sur le Registre National d'Aptitude basée à Durtal (72) dans le secteur du Mans et d'Angers.</p>
                        <a href='/contact'>Me contacter</a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   );
};

export default Home;
