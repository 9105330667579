import { About, Care, Testimonials, Who } from '../components';
import Contact from '../components/Contact';

const MainPage = () => {
   return (
      <>
         <About />
         <Care />
         <Who />
         <Testimonials />
         <Contact />
      </>
   );
};
export default MainPage;
