import { useMemo } from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { GOOGLE_API_KEY } from '../constants/env';
import { Contacts, SocialBox } from './index';

const Contact = () => {
   const center = useMemo(() => ({ lat: 47.688737973851, lng: -0.263804957584 }), []);

   return (
      <section className='contact_section layout_padding'>
         <div className='container'>
            <div className='heading_container'>
               <h2>Coordonnées</h2>
            </div>
            <div className='row'>
               <div className='col-md-6'>
                  <div className='mb-4'>
                     Pour toute prise de contact ou demande de rdv, merci de me préciser vos nom et prénom, l'identité de votre animal (nom, race, âge), le motif de la
                     consultation, ainsi que l'adresse exacte où celle-ci aura lieu, pour les séances se faisant à domicile, sans oublier vos disponibilités particulières.
                  </div>
                  <Contacts />
                  <SocialBox />
               </div>
               <div className='col-md-6'>
                  <APIProvider apiKey={GOOGLE_API_KEY}>
                     <div className='map_container'>
                        <div className='map'>
                           <Map zoom={8} center={center} gestureHandling={'greedy'} disableDefaultUI={true}>
                              <Marker position={center} />
                           </Map>
                        </div>
                     </div>
                  </APIProvider>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Contact;
