import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Contacts = () => {
   return (
      <>
         <div className='contact'>
            <FontAwesomeIcon icon={faPhone} className='mr-2' />
            <a href='tel:+33787988872'>07 87 98 88 72</a>
         </div>
         <div className='contact mt-2'>
            <FontAwesomeIcon icon={faEnvelope} className='mr-2' />
            <a href='mailto:lisedaguet.osteo@gmail.com'>lisedaguet.osteo@gmail.com</a>
         </div>
         <div className='contact mt-2'>
            <FontAwesomeIcon icon={faClock} className='mr-2' />
            <div className='times'>
               <div>Du lundi au vendredi : de 9h00 à 20h</div>
               <div>Samedi : de 9h00 à 13h00</div>
            </div>
         </div>
      </>
   );
};

export default Contacts;
