import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCat, faDog, faHorseHead, faCow, faDove, faCar } from '@fortawesome/free-solid-svg-icons';

const Prices = () => {
   return (
      <section className='service_section layout_padding'>
         <div className='container py_mobile_45'>
            <div className='heading_container heading_center'>
               <h2>Tarifs</h2>
            </div>
            <div className='row'>
               <div className='col-md-4'>
                  <div className='box '>
                     <div className='img-box'>
                        <FontAwesomeIcon icon={faHorseHead} />
                     </div>
                     <div className='detail-box'>
                        <h5>Cheval</h5>
                        <p>85 €</p>
                     </div>
                  </div>
               </div>
               <div className='col-md-4'>
                  <div className='box '>
                     <div className='img-box'>
                        <FontAwesomeIcon icon={faDog} />
                     </div>
                     <div className='detail-box'>
                        <h5>Chien</h5>
                        <p>55 €</p>
                     </div>
                  </div>
               </div>
               <div className='col-md-4'>
                  <div className='box '>
                     <div className='img-box'>
                        <FontAwesomeIcon icon={faCat} />
                     </div>
                     <div className='detail-box'>
                        <h5>Chat</h5>
                        <p>55 €</p>
                     </div>
                  </div>
               </div>
               <div className='col-md-4'>
                  <div className='box '>
                     <div className='img-box'>
                        <FontAwesomeIcon icon={faCow} />
                     </div>
                     <div className='detail-box'>
                        <h5>Bovin</h5>
                        <p>85 €</p>
                     </div>
                  </div>
               </div>
               <div className='col-md-4'>
                  <div className='box '>
                     <div className='img-box'>
                        <FontAwesomeIcon icon={faDove} />
                     </div>
                     <div className='detail-box'>
                        <h5>NAC</h5>
                        <p>45 €</p>
                     </div>
                  </div>
               </div>
               <div className='col-md-4'>
                  <div className='box '>
                     <div className='img-box'>
                        <FontAwesomeIcon icon={faCar} />
                     </div>
                     <div className='detail-box'>
                        <h5>Frais de déplacement</h5>
                        <p>De 0 à 30 km : pas de frais</p>
                        <p>De 30 à 40 km: +5€ </p>
                        <p>De 40 à 50 km: +10€</p>
                        <p>De 50 à 60 km : consultation +15€</p>
                        <p>+ 60 km : me contacter</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Prices;
